<template>
  <div class="upload-popup">
    <h3 class="title">{{ app.locale.upload_title }}</h3>
    <div class="list">
      <div
        v-for="(element) in list"
        :key="element.id"
        class="list-element"
      >
        <div class="calc-name">
          {{ app.locale.upload_calc }} {{ element.name }} 
          <br />
          {{ element.date.toLocaleDateString('ru-RU',{ year: 'numeric', month: 'short', day: 'numeric' }) }}
        </div>
        <div class="calc-cost">
          {{ app.locale.upload_calc_cost }} {{ element.cost }} {{app.locale.currency_symbol}}
        </div>
        <Button
          class="upload-btn"
          icon="upload"
          icon-color="#ffffff"
          :icon-size="app.breakpoint.mobile ? ['12px', '12px'] : ['16px', '16px']"
          icon-position="center"
          @click="load(element.id)"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { Button } from "@components/UI"
import { useState } from "@src/App.store.js"
// import { GetUser } from "@src/API"
import "./Upload.scss"

export default {
  name: "UploadPopup",
  components: {
    Button
  },
  data(){
    return {
      // list: [{
      //   id: 0,
      //   name: "calcName",
      //   date: new Date(),
      //   cost: '564 556'
      // }, {
      //   id: 1,
      //   name: "calcName",
      //   date: new Date(),
      //   cost: '564 556'
      // }, {
      //   id: 2,
      //   name: "calcName",
      //   date: new Date(),
      //   cost: '3 132 456'
      // }, {
      //   id: 3,
      //   name: "calcName",
      //   date: new Date(),
      //   cost: '656 556'
      // }]
    }
  },
  computed: {
    list() {
      let out = []

      if (this.app.user)
        for(let node of this.app.user.nodes){
          out.push({
            id: node.id,
            name: node.name,
            date: new Date(node.updated_at),
            cost: node.total.toLocaleString('ru-RU')
          })
        }

      return out
    }
  },
  setup(){
    return useState()
  },
  methods: {
    load(id) {
      // this.app.currn
      this.loadTree(id)
      this.app.currentPopup.openSwitch()
    }
  }
}
</script>