/* eslint-disable */

<template>
  <div class="settings-popup">
    <div class="group">
      <Button
        icon="plus"
        icon-position="left"
        :icon-size="buttonIconSize"
        icon-color="#444444"
        style="color: #444444"
        @click="Reset"
      >
        {{ app.locale.buttons.new_calc }}
      </Button>
      <Button
        icon="save"
        icon-position="left"
        :icon-size="buttonIconSize"
        :icon-color="saveSuccess ? '#3FA43D' : '#444444'"
        :style="`color: ${saveSuccess ? '#3FA43D' : '#444444'}`"
        @click="save"
      >
        {{
          saveSuccess ? app.locale.buttons.save_calc_success : app.locale.buttons.save_calc
        }}
      </Button>
      <Popup
        class="settings-btn"
      >
        <template v-slot:popup-button>
          <Button
            icon="upload"
            :icon-position="'left'"
            :icon-size="buttonIconSize"
            icon-color="#444444"
            :style="{color: '#444444'}"
          >
            {{ app.locale.buttons.upload_calc }}
          </Button>
        </template>
        <template v-slot:default>
          <UploadPopup />
        </template>
      </Popup>
    </div>
     <hr class="divider" v-if="app.calculator.clientType === 'ABO'" />
    <div class="group"  v-if="app.calculator.clientType === 'ABO'">


      <Button
        style="color: #444444"
        @click="load(`4447d4b6-8e12-4e24-8f68-8118851fa911`)"
      >
         <b style="margin-left: 4px">100&nbsp;000{{app.locale.currency_symbol}}&nbsp;</b> {{ app.locale.example_calc_for }}
      </Button>

      <Button
        style="color: #444444"
        @click="load(`61af360b-69f5-4fdd-a88f-f0a06b71cd70`)"
      >
         <b style="margin-left: 4px">300&nbsp;000{{app.locale.currency_symbol}}&nbsp;</b> {{ app.locale.example_calc_for }}
      </Button>

      <Button
        style="color: #444444"
        @click="load(`296644a4-4ad6-4c69-a05e-8b6f3211bdc5`)"
      >
         <b style="margin-left: 4px">600&nbsp;000{{app.locale.currency_symbol}}&nbsp;</b> {{ app.locale.example_calc_for }}
      </Button>
    </div>
    <hr class="divider"/>
    <div class="group">
      <!-- <Button
        icon="instruction"
        icon-position="left"
        :icon-size="buttonIconSize"
        icon-color="#444444"
        style="color: #444444"
      >
        {{ app.locale.buttons.instructions }}
      </Button> -->
      <a
        class="btn withIcon"
        style="color: #444444"
        :href="app.locale.instruction"
        download
      >
        <Icon
          class="icon left"
          :class="iconPosition"
          name="instruction"
          color="#444444"
          :width="buttonIconSize[0]"
          :height="buttonIconSize[1]"
        />
        {{ app.locale.buttons.instructions }}
      </a>
      <Button
        icon="learn"
        icon-position="left"
        :icon-size="buttonIconSize"
        icon-color="#444444"
        style="color: #444444"
        @click="app.saved ? startTutorial : startTutorialWithUnsave = true"
      >
        {{ app.locale.buttons.start_learn }}
      </Button>
      <Button
        icon="close"
        icon-position="left"
        :icon-size="buttonIconSize"
        icon-color="#D64848"
        style="color: #D64848"
      >
        {{ app.locale.buttons.exit }}
      </Button>
    </div>

    <Popover v-if="app.tutorialStep == 'settings-popup-helper'"
      :class="this.app.tutorialStep"
      :title="this.app.locale.tutorial['settings-popup-title']"
      :text="this.app.locale.tutorial['settings-popup-text']"
      :isArrow="true"
      arrowPosition="top"
      arrowAlign="left"
    />
  </div>

  <div v-if="startTutorialWithUnsave ? true : (!app.saved && startTutorialWithUnsave)" class="unsave-tutorial-message">
    <div class="message">
      <div class="title">
        {{ app.locale.unsave.title }}
      </div>
      <div class="text">
        {{ app.locale.unsave.text }}
      </div>
    </div>

    <div class="actions">
      <Button
        id="save"
        type="basic"
        icon="save"
        icon-position="right"
        :icon-size="buttonIconSize"
        :icon-color="'#FFFFFF'"
        :style="{ color: '#FFFFFF' }"
        @click="save"
      >
        {{
          saveSuccess ? app.locale.buttons.save_calc_success : app.locale.buttons.save_calc
        }}
      </Button>
      <Button
        id="startTutor"
        type="basic"
        icon="learn"
        icon-position="right"
        :icon-size="buttonIconSize"
        icon-color="#034580"
        style="color: #034580"
        @click="startTutorial"
      >
        {{ app.locale.buttons.start_learn }}

      </Button>
    </div>
  </div>

</template>
<script>
import { Button, Popup, Icon, Popover } from "@components/UI"
import { useState } from "@src/App.store.js"
import { baseUrl } from "@src/API/Fetch.js"
import "./Settings.scss"

import UploadPopup from "@src/popups/Upload"

export default {
  name: "SettingsPopup",
  components: {
    Button,
    Popup,
    Icon,
    UploadPopup,
    Popover
  },
  setup(){
    return useState()
  },
  data(){
    return {
      buttonIconSize: ["16px", "16px"],
      saveSuccess: false,
      startTutorialWithUnsave: false,
    }
  },
  computed: {

    downloadLink() {
      return new URL(`/node/${ this.Tree.id }/pdf`, baseUrl)
    }
  },
  methods: {
    save() {
      this.saveSuccess = true
      this.saveTree()
      setTimeout(() => {
        this.saveSuccess = false
      }, 10000)
    },
    load(id) {
      // this.app.currn
      this.loadTree(id)
      this.app.currentPopup.openSwitch()
    }
  }
}
</script>
