import { v4 as uuid } from "uuid";

export default {
  data() {
    return {
      duplicatedTarget: undefined,
      duplicationStarted: false,
      staticData: Object.seal({
        maxDepth: 20,
        maxChildren: 3,
        childrenLimit: 6,
      }),
    };
  },
  watch: {
    duplicateEnabled: {
      handler() {
        this.setShowDuplicateButton(
          this.haveChildren && this.maxChildren && this.onlyLevel
        );
      },
    },
  },
  computed: {
    // Duplicate button state variable
    duplicateEnabled() {
      return (
        this.getDepth(this.tree) <= this.staticData.maxDepth &&
        this.maxChildren &&
        this.onlyLevel &&
        this.haveChildren
      );
    },

    onlyLevel() {
      if (this.duplicationStarted) return true;

      return !this.tree.children.some((child) => child.children.length);
    },

    maxChildren() {
      if (this.duplicationStarted) return true;

      return this.tree?.children.length <= this.staticData.maxChildren;
    },

    haveChildren() {
      return !!this.tree.children.length;
    },
  },
  methods: {
    startDuplicating(id) {
      this.unselect();
      if (!this.duplicateEnabled) return false;

      this.duplicationStarted = true;

      this.duplicatedTarget = JSON.parse(
        window.localStorage.getItem("duplicateItem")
      );

      if (!this.duplicatedTarget) {
        this.duplicatedTarget = this.getById(id);
        window.localStorage.setItem(
          "duplicateItem",
          JSON.stringify(this.duplicatedTarget)
        );
      }

      this.recursiveSetDuplicate(this.tree, this.duplicatedTarget.children);

      return true;
    },

    // Changing id of duplicated items and setting to item
    setDuplicateChildren(item, duplicateItems) {
      const newChildren = duplicateItems.map((child) => ({
        ...child,
        id: uuid(),
        children: [],
      }));

      item.children.push(...newChildren);
    },

    // Recursive setting of duplicates
    recursiveSetDuplicate(item, duplicateItems) {
      if (item.children.length) {
        item.children.forEach((child) =>
          this.recursiveSetDuplicate(child, duplicateItems)
        );
      }

      const childrenAmount = item.own_children || 0;

      if (childrenAmount < this.staticData.childrenLimit) {
        if (
          childrenAmount + duplicateItems.length <=
          this.staticData.childrenLimit
        ) {
          this.setDuplicateChildren(item, duplicateItems);
        } else {
          this.setDuplicateChildren(
            item,
            duplicateItems.slice(
              0,
              this.staticData.childrenLimit - childrenAmount
            )
          );
        }
      }
    },

    // Get tree depth
    getDepth(item, counter = 0) {
      let depth = counter;

      if (item.children.length) {
        const depths = item.children.map((child) => {
          return this.getDepth(child, depth + 1);
        });

        depth = depths[0];
      }

      return depth;
    },
  },
};
