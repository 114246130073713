<template>
  <div class="input-container" :class="[type]">
    <div class="label" v-if="label">{{ label }}</div>
    <Button
      v-if="type == 'number'"
      class="minus"
      icon="minus"
      :icon-size="['16px', '16px']"
      icon-position="center"
      @click.exact="minus(stepLength)"
      @click.ctrl="minus(stepLength * 10)"
      @click.shift="minus(stepLength * 100)"
    />
    <input
      class="amway-input"
      :class="[type]"
      :value="currentValue ? currentValue : ''"
      :placeholder="type == 'number' && 0"
      @input="change"
    />

    <Button
      v-if="withClear"
      class="clear"
      icon="close"
      icon-position="center"
      icon-color="#f00"
      :icon-size="app.breakpoint.mobile ? ['12px', '12px'] : ['16px', '16px']"
      @click="currentValue = ''"
    />

    <Button
      v-if="type == 'number'"
      class="plus"
      icon="plus"
      :icon-size="['16px', '16px']"
      icon-position="center"
      @click.exact="plus(stepLength)"
      @click.ctrl="plus(stepLength * 10)"
      @click.shift="plus(stepLength * 100)"
    />
    <Popover
      v-if="tutorial"
      :class="tutorial.align"
      :title="tutorial.title"
      :text="tutorial.text"
      :isArrow="true"
      :arrowAlign="app.breakpoint.mobile ? tutorial.align : 'center'"
    />
  </div>
</template>
<script>
import { Button, Popover } from "@components/UI";
import { useState } from "@src/App.store.js";
import "./Input.scss";

export default {
  name: "Input",
  components: {
    Button,
    Popover,
  },
  props: {
    type: {
      type: String,
      default: "text",
    },
    stepLength: {
      default: 100,
    },
    value: {
      default: null,
    },
    minMax: {
      default: null,
    },
    mask: {
      default: null,
    },
    label: {
      default: null,
    },
    withClear: {
      default: false,
    },
    tutorial: {},
  },
  data() {
    let currentValue = this.value;

    if (this.type == "number" && !currentValue) currentValue = 0;
    else if (!this.value) currentValue = "";

    if (this.type == "number" && currentValue) {
      if (typeof currentValue == "string")
        currentValue = Number(currentValue.replace(/[^0-9-]/g, ""));
      currentValue = currentValue.toLocaleString("ru-RU");
    }

    return {
      currentValue,
    };
  },
  setup() {
    return useState();
  },
  watch: {
    currentValue() {
      let current = this.currentValue;

      if (this.type == "number") {
        if (typeof current == "string")
          current = Number(this.currentValue.replace(/[^0-9-]/g, ""));
        if (current <= 0) current = 0;
        if (current) {
          this.currentValue = current.toLocaleString("ru-RU");
        }
      }
      this.change();
    },
  },
  methods: {
    change(event = {}) {
      if (event.target) {
        this.currentValue = event.target.value;
      }

      let value = this.currentValue;

      if (this.type == "number")
        if (typeof this.currentValue == "string")
          value = Number(this.currentValue.replace(/[^0-9-]/g, ""));

      this.$emit("change", value);
    },
    plus(step = this.stepLength) {
      let value = this.currentValue;
      if (this.type == "number")
        if (typeof value == "string")
          value = Number(this.currentValue.replace(/[^0-9-]/g, ""));
      this.currentValue = value + step;
      this.change();
    },
    minus(step = this.stepLength) {
      if (!this.currentValue) return;

      let value = this.currentValue;

      if (this.type == "number")
        if (typeof value == "string")
          value = Number(this.currentValue.replace(/[^0-9-]/g, ""));
      this.currentValue = value - step;

      if (this.currentValue < 0) {
        this.currentValue = 0;
      }

      this.change();
    },
  },
};
</script>
