import { createApp } from 'vue'
import App from './App.vue'
import router from "./router/index";
import { stateSymbol, createState } from './App.store.js'

import MoveHelper from './plugins/MoveHelper.js'

// eslint-disable-next-line
const app = createApp(App)
  .provide(stateSymbol, createState({
    locale: "ru"
  }))
  .use(MoveHelper).use(router)
  .mount('#app')

