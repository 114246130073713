export function percentLevel(gpv) {
  if (gpv < 200) return 0;
  else if (gpv < 600) return 3;
  else if (gpv < 1200) return 6;
  else if (gpv < 2400) return 9;
  else if (gpv < 4000) return 12;
  else if (gpv < 7000) return 15;
  else if (gpv < 10000) return 18;
  else return 21;
}
