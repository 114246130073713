<template>
  <div class="calculator-page">
    <div class="menu">
      <div class="client-type" :class="app.calculator.clientType">
        {{
          app.calculator.clientType == "ABO"
            ? app.locale.lang_npa
            : app.locale.lang_vip
        }}
      </div>
      <div class="income">
        {{ app.locale.income_label }}:
        <button class="income__button" @click="openAwardsModal">
          <span class="income-cost">
            {{ app.calculator.total.toLocaleString("ru-RU") }}
            {{ app.locale.currency_symbol }}
          </span>
        </button>
      </div>

      <Popup ref="popup" class="settings-btn">
        <template v-slot:popup-button>
          <Button
            icon="settings"
            icon-color="#56565A"
            :icon-size="
              app.breakpoint.mobile ? ['16px', '16px'] : ['24px', '24px']
            "
            :icon-position="app.breakpoint.mobile ? 'center' : 'right'"
            :style="{ color: '#56565A' }"
            @click="
              app.tutorialStep == 'settings-open' &&
                changeTutorialStep('settings-popup-helper');
              tutorialHandler();
            "
          >
            <span class="title">
              {{ app.locale.buttons.settings }}
            </span>
          </Button>
        </template>
        <template v-slot:default>
          <SettingsPopup />
        </template>
      </Popup>

      <Popover
        v-if="app.tutorialStep == 'settings-open'"
        :class="this.app.tutorialStep"
        :title="this.app.locale.tutorial['settings-open-title']"
        :text="this.app.locale.tutorial['settings-open-text']"
        :isArrow="true"
        arrowPosition="top"
        arrowAlign="right"
      />
    </div>

    <Canvas ref="canvas" />

    <div v-if="showDuplicateButton" class="calculator-page__button-wrapper">
      <button class="calculator-page__duplicate-button" @click="duplicate">
        {{ app.locale.duplicate}} {{ duplicateCounter }}/{{ duplicateLimit }}
      </button>
    </div>
  </div>
</template>
<script>
import { Canvas } from "@components/Diagram";
import { Popup, Button, Popover } from "@components/UI";
import { useState } from "@src/App.store.js";
import { computed } from "vue";

import SettingsPopup from "@src/popups/Settings";

import "./calculator.scss";

export default {
  name: "calcPage",
  components: {
    Canvas,
    Popup,
    Button,
    SettingsPopup,
    Popover,
  },
  provide() {
    return {
      incrementDuplicateCounter: this.incrementDuplicateCounter,
      duplicateCounter: computed(() => this.duplicateCounter),
      duplicateLimit: this.duplicateLimit,
      setShowDuplicateButton: this.setShowDuplicateButton,
    };
  },
  data() {
    return {
      duplicateCounter: 0,
      duplicateLimit: 5,
      showDuplicateButton: false,
    };
  },
  setup() {
    return useState();
  },
  methods: {
    tutorialHandler() {
      if (this.app.tutorialStep)
        setTimeout(() => {
          this.changeTutorialStep("end");
          this.app.currentPopup.openSwitch();
        }, 1500);
    },
    duplicate() {
      this.$refs.canvas.$refs.tree.duplicate();
    },
    incrementDuplicateCounter() {
      this.duplicateCounter++;
    },
    openAwardsModal() {
      this.$refs.canvas.$refs.tree.$refs.branch.$refs.vertex.$refs.EditPopup.openSwitch();
    },
    setShowDuplicateButton(value) {
      this.showDuplicateButton = value;
    },
  },
};
</script>
