import Fetch from "@src/API/Fetch.js"
import Format from "@src/utils/Format.js"

export default async function Load(id) {
  let result;

  if(/preset:/.test(id)){
    let [,presetName] = id.split(':')
    let response = await fetch(`/preset/${presetName}.json`, {
      method: "GET"
    });

    if(response.ok) {
      result = await response.json();
    } else {
      throw 'Failed'
    }
  } else {
    result = await Fetch("GET", `node/${id}`)
  }
  
  return await Format("tree", result);
}