<template>
  <Branch
    v-for="child in vertex.children"
    :id="`vertex-${child.id}`"
    :x="vertex.x"
    :y="vertex.y"
    :hide="vertex.hideChildren"
    :key="child.id"
    :vertex="child"
    @add="$emit(`add`, $event)"
    @remove="$emit(`remove`, $event)"
    @select="$emit(`select`, $event)"
    @unselect="$emit(`unselect`, $event)"
    @hide-tree="$emit(`hide-tree`, $event)"
    @update="$emit('update', $event)"
  />
  <g v-if="!(vertex.hide || vertex.hideChildren)">
    <path
      v-for="(child, key) in vertex.children"
      :key="key"
      stroke="#B1B7CF"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
      fill-opacity="0"
      marker-end="url(#arrowCap)"
      :d="arrow(child, key)"
    />
  </g>

  <Vertex
    ref="vertex"
    :id="vertex.id"
    :key="vertex.id"
    :x="hide ? x : vertex.x"
    :y="hide ? y : vertex.y"
    :percent="vertex.percent"
    :mode="vertex.mode"
    :hideChildren="vertex.hideChildren"
    :hide="vertex.hide"
    :vertex="vertex"
    @add="$emit(`add`, $event)"
    @remove="$emit(`remove`, $event)"
    @select="$emit(`select`, $event)"
    @unselect="$emit(`unselect`, $event)"
    @hide-tree="$emit(`hide-tree`, $event)"
    @update="$emit('update', $event)"
  />
</template>
<script>
/* eslint-disable */
import Vertex from "@components/Diagram/Vertex";

export default {
  name: "Branch",
  components: {
    Vertex,
  },
  props: {
    vertex: Object,
    key: String,
    x: Number,
    y: Number,
    hide: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    arrow(child, index) {
      let d = ``;

      if (this.vertex.x > child.x) {
        let prevX =
          this.vertex.children[index + 1] && this.vertex.children[index + 1].x;

        if (prevX < this.vertex.x) {
          d += `M${prevX + 250 / 2} ${this.vertex.y + 280} H ${
            child.x + 290 / 2
          } q-20 0 -20 20`;
        } else
          d += `M${this.vertex.x + 250 / 2} ${
            this.vertex.y + 260
          } q0 20 -20 20 H ${child.x + 290 / 2} q-20 0 -20 20`;
      } else if (this.vertex.x < child.x) {
        let prevX =
          this.vertex.children[index - 1] && this.vertex.children[index - 1].x;

        if (prevX > this.vertex.x) {
          d += `M${prevX + 250 / 2} ${this.vertex.y + 280} H ${
            child.x + 210 / 2
          } q20 0 20 20`;
        } else
          d += `M${this.vertex.x + 250 / 2} ${
            this.vertex.y + 260
          } q0 20 20 20 H ${child.x + 210 / 2} q20 0 20 20`;
      } else {
        d += `M${this.vertex.x + 250 / 2} ${this.vertex.y + 260} V ${
          child.y - 5
        }`;
      }

      return d;
    },
  },
  mounted() {},
};
</script>
