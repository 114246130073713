<template>
  <footer>
    <img width="70" height="23" src="../../../assets/logo.png"/>
    <p class="copyrate">
      {{app.locale.copyrate}}
    </p>
    <div class="links">
      <a href="www.alticor.com">
        www.alticor.com
      </a>
      <span class="separator"/>
      <a href="www.amwayglobal.com">
        www.amwayglobal.com
      </a>
    </div>
  </footer>
</template>
<script>
import { useState } from "@src/App.store.js"

export default {
  setup(){
    return useState()
  }  
}
</script>