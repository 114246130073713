<template>
  <IconsSprite />

  <router-view> </router-view>

</template>

<script>
  import { IconsSprite } from "@components/UI/Icon"


  export default {
    name: 'App',
    components: {
      IconsSprite,
    },
  }
</script>

<style>
  * {
    margin: 0;
    padding: 0;
  }
  #app {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
  }
</style>
