<template>
  <foreignObject
    :class="[
      'vertex-container',
      {
        'current-tutorial':
          (this.app.tutorialStep == 'vertex-start-edit' ||
            this.app.tutorialStep == 'vertex-start-edit-2' ||
            this.app.tutorialStep == 'vertex-personal-turnover-up' ||
            this.app.tutorialStep == 'vertex-add-partner' ||
            this.app.tutorialStep == 'vertex-add-partner-2' ||
            this.app.tutorialStep == 'vertex-add-partner-3' ||
            this.app.tutorialStep == 'vertex-hide-tree' ||
            this.app.tutorialStep == 'vertex-show-tree' ||
            this.app.tutorialStep == 'vertex-view-detail-delay' ||
            this.app.tutorialStep == 'vertex-view-detail-info' ||
            this.app.tutorialStep == 'vertex-view-detail-info-2') &&
          vertex.root,
      },
    ]"
    height="250"
    width="250"
    :x="x"
    :y="y"
    ref="vertexContainer"
  >
    <svg viewBox="-25 -25 250 250" :x="x" :y="y">
      <g
        class="vertex-clip"
        v-bind:class="[
          `${mode}-mode`,
          `percent-${level || 0}`,
          {
            hide: hide,
            root: vertex.root,
          },
        ]"
        ref="vertex"
      >
        <g class="view" @click="$emit('select', vertex.id)">
          <rect class="BG" width="200" height="200" />
          <g class="percent">
            <text>
              <tspan class="prefix" dx="-6">
                {{
                  app.calculator.clientType == "ABO"
                    ? app.locale.lang_npa
                    : "VIP+"
                }}
              </tspan>
              <tspan dx="10">
                {{ level || 0 }}
              </tspan>
            </text>
            <use
              :x="String(level).length == 2 ? 42 : 36"
              y="-23"
              class="percent-symbol"
              height="14"
              width="14"
              xlink:href="#percentSymbol"
              style="opacity: 1; fill: #ffffff"
            />
          </g>
          <g class="PPV">
            <text>
              {{ app.locale.PPV }}
            </text>
            <text class="value">{{ vertex.personal_points }}</text>
          </g>
          <g class="GPV">
            <text>
              {{ app.locale.GPV }}
            </text>
            <text class="value">{{ vertex.group_points }}</text>
          </g>
          <g v-if="vertex.root">
            <rect y="128" width="200" height="72" fill="#FFFFFF" />

            <g class="Money">
              <text>
                {{ app.calculator.total.toLocaleString("ru-RU") }}
                <tspan class="currency" dx="-3" dy="-1">
                  {{ app.locale.currency_symbol }}
                </tspan>
              </text>
            </g>
          </g>
        </g>

        <g class="active">
          <rect class="BG" width="200" height="200" />

          <foreignObject class="editButton" height="200" width="200">
            <Popup ref="EditPopup" @click="tutorialHandler('edit')">
              <template v-slot:popup-button>
                <button v-html="app.locale.buttons.edit"></button>
              </template>
              <template v-slot:default>
                <EditPopup
                  :vertex="vertex"
                  :percentState="level"
                  @update="$emit('update', $event)"
                />
              </template>
              <template v-slot:close-btn="slotProps">
                <Button
                  icon="close"
                  icon-position="center"
                  icon-color="inherit"
                  :circle="true"
                  :icon-size="
                    app.breakpoint.mobile ? ['12px', '12px'] : ['16px', '16px']
                  "
                  :class="[`percent-${level || 0}`, 'VertexEditClose']"
                  @click="slotProps.openSwitch"
                />
              </template>
            </Popup>
          </foreignObject>
        </g>

        <foreignObject
          class="treeSwitchButton"
          :class="{ 'active-hide': hideChildren }"
          height="40"
          width="40"
          v-if="vertex.children.length > 0"
        >
          <button
            @click="
              $emit('hide-tree', vertex.id);
              tutorialHandler('hide');
            "
          >
            <Icon
              name="show-hide"
              height="24px"
              width="24px"
              :color="hideChildren ? '#ffffff' : '#b1b7cf'"
            />
          </button>
        </foreignObject>

        <foreignObject class="moreButton" height="40" width="40" ref="moreBtn">
          <button
            @click="
              $emit('select', vertex.id);
              tutorialHandler('more');
            "
          >
            <Icon name="more" height="24px" width="24px" color="#ffffff" />
          </button>
        </foreignObject>
        <foreignObject
          v-if="!vertex.root"
          class="removeButton"
          height="40"
          width="40"
        >
          <button @click="$emit(`remove`, vertex.id)">
            <Icon name="basket" height="24px" width="24px" color="#ffffff" />
          </button>
        </foreignObject>
        <foreignObject class="addButton" height="40" width="40" ref="addBtn">
          <button
            @click="
              $emit('add', vertex.id);
              tutorialHandler('add');
            "
          >
            <Icon name="plus" height="24px" width="24px" color="#ffffff" />
          </button>
        </foreignObject>
      </g>
    </svg>
  </foreignObject>
  <foreignObject
    v-if="tutorialState"
    width="0"
    height="0"
    class="popover-foreign"
  >
    <teleport to=".tutorial-layout">
      <Popover
        v-if="tutorialState && app.tutorialStep == 'vertex-start-edit'"
        :class="[
          this.app.tutorialStep,
          {
            hide: !(
              this.tutorialState &&
              this.isMounted &&
              this.vertexRect &&
              this.vertexContainerRect &&
              this.addRect &&
              this.moreRect
            ),
          },
        ]"
        :text="this.app.locale.tutorial['vertex-start-edit']"
        :x="tutorial.x"
        :y="tutorial.y"
        :isArrow="true"
        :arrowPosition="app.breakpoint.mobile ? 'top' : 'left'"
        :arrowAlign="app.breakpoint.mobile ? 'right' : 'center'"
      />
      <Popover
        v-if="
          tutorialState && app.tutorialStep == 'vertex-personal-turnover-up'
        "
        :class="this.app.tutorialStep"
        :title="this.app.locale.tutorial['vertex-personal-turnover-up-title']"
        :text="`+${this.vertex.personal_points - 100} ${
          this.app.locale.tutorial['vertex-personal-turnover-up-text']
        }`"
        :x="tutorial.x"
        :y="tutorial.y"
        :isArrow="false"
      />
      <Popover
        v-if="tutorialState && app.tutorialStep == 'vertex-ext-menu'"
        :class="this.app.tutorialStep"
        :title="this.app.locale.tutorial['vertex-ext-menu-title']"
        :text="this.app.locale.tutorial['vertex-ext-menu-text']"
        :x="tutorial.x"
        :y="tutorial.y"
        :isArrow="true"
        arrowPosition="top"
        arrowAlign="right"
      />

      <Popover
        v-if="tutorialState && app.tutorialStep == 'vertex-add-partner'"
        :class="this.app.tutorialStep"
        :title="this.app.locale.tutorial['vertex-add-partner-title']"
        :text="this.app.locale.tutorial['vertex-add-partner-text']"
        :x="tutorial.x"
        :y="tutorial.y"
        :isArrow="true"
        arrowPosition="top"
        arrowAlign="center"
      />
      <Popover
        v-if="tutorialState && app.tutorialStep == 'vertex-add-partner-2'"
        :class="this.app.tutorialStep"
        :title="this.app.locale.tutorial['vertex-add-partner-2-title']"
        :text="this.app.locale.tutorial['vertex-add-partner-2-text']"
        :x="tutorial.x"
        :y="tutorial.y"
        :isArrow="true"
        arrowPosition="top"
        arrowAlign="center"
      />
      <Popover
        v-if="tutorialState && app.tutorialStep == 'vertex-add-partner-3'"
        :class="this.app.tutorialStep"
        :title="this.app.locale.tutorial['vertex-add-partner-3-title']"
        :text="this.app.locale.tutorial['vertex-add-partner-3-text']"
        :x="tutorial.x"
        :y="tutorial.y"
        :isArrow="true"
        arrowPosition="top"
        arrowAlign="center"
      />
      <Popover
        v-if="tutorialState && app.tutorialStep == 'vertex-add-partner-3'"
        :class="this.app.tutorialStep"
        :title="this.app.locale.tutorial['vertex-add-partner-3-title']"
        :text="this.app.locale.tutorial['vertex-add-partner-3-text']"
        :x="tutorial.x"
        :y="tutorial.y"
        :isArrow="true"
        arrowPosition="top"
        arrowAlign="center"
      />
      <Popover
        v-if="
          tutorialState &&
          (app.tutorialStep == 'vertex-hide-tree' ||
            app.tutorialStep == 'vertex-show-tree')
        "
        :class="this.app.tutorialStep"
        :title="this.app.locale.tutorial['vertex-hide-tree-title']"
        :text="this.app.locale.tutorial['vertex-hide-tree-text']"
        :x="tutorial.x"
        :y="tutorial.y"
        :isArrow="true"
        arrowPosition="top"
        arrowAlign="center"
      />
      <Popover
        v-if="tutorialState && app.tutorialStep == 'vertex-view-detail-info'"
        :class="this.app.tutorialStep"
        :title="this.app.locale.tutorial['vertex-view-detail-info-title']"
        :text="this.app.locale.tutorial['vertex-view-detail-info-text']"
        :x="tutorial.x"
        :y="tutorial.y"
        :isArrow="true"
        :arrowPosition="app.breakpoint.mobile ? 'top' : 'left'"
        :arrowAlign="app.breakpoint.mobile ? 'right' : 'center'"
      />
    </teleport>
  </foreignObject>
</template>

<script>
import { Icon, Button, Popup, Popover } from "@components/UI";
import EditPopup from "@src/popups/Edit-vertex";
import { useState } from "@src/App.store.js";
import "./Vertex.scss";

export default {
  name: "Vertex",
  components: {
    Icon,
    Popup,
    Button,
    EditPopup,
    Popover,
  },
  props: {
    x: {
      type: Number,
      default: 0,
    },
    y: {
      type: Number,
      default: 0,
    },
    percent: {
      type: Number,
      default: 0,
    },
    mode: {
      type: String,
      default: "view",
    },
    hideChildren: {
      type: Boolean,
      default: false,
    },
    hide: {
      type: Boolean,
      default: false,
    },
    vertex: {
      type: Object,
    },
  },
  setup() {
    return useState();
  },
  data() {
    return {
      isMounted: false,
      vertexRect: null,
      addRect: null,
      moreRect: null,
    };
  },
  watch: {
    vertex: {
      deep: true,
      handler() {
        this.$forceUpdate();
      },
    },
    tutorialStep: {
      handler() {
        if (this.tutorialStep == "vertex-personal-turnover-up") {
          this.$emit("unselect", this.vertex.id);

          setTimeout(() => {
            this.changeTutorialStep("vertex-ext-menu");
          }, 2000);
        }
      },
    },
  },
  computed: {
    level() {
      return this.vertex.level;
    },
    tutorialStep() {
      return this.app.tutorialStep;
    },
    tutorialState() {
      if (!this.vertex.root) return false;

      return (
        this.app.tutorialStep == "vertex-start-edit" ||
        this.app.tutorialStep == "vertex-personal-turnover-up" ||
        this.app.tutorialStep == "vertex-ext-menu" ||
        this.app.tutorialStep == "vertex-add-partner" ||
        this.app.tutorialStep == "vertex-add-partner-2" ||
        this.app.tutorialStep == "vertex-add-partner-3" ||
        this.app.tutorialStep == "vertex-hide-tree" ||
        this.app.tutorialStep == "vertex-show-tree" ||
        this.app.tutorialStep == "vertex-view-detail-delay" ||
        this.app.tutorialStep == "vertex-view-detail-info" ||
        this.app.tutorialStep == "vertex-collapse-tree" ||
        this.app.tutorialStep == "vertex-open-edit"
      );
    },
    tutorial() {
      if (
        !this.tutorialState ||
        !this.isMounted ||
        !this.vertexRect ||
        !this.vertexContainerRect ||
        !this.addRect ||
        !this.moreRect
      )
        return {};

      let tutorial = {
        x: 0, //this.rect.x,
        y: 0, //this.rect.y,
      };

      switch (this.app.tutorialStep) {
        case "vertex-start-edit":
          if (this.app.breakpoint.mobile) {
            tutorial.x =
              this.vertexRect.x -
              (248 -
                (this.vertexRect.width / 100) * 76.5 -
                22.605 -
                this.moreRect.width / 2);
            tutorial.y =
              this.vertexRect.y +
              this.moreRect.height +
              (this.vertexRect.height / 100) * 2.5 +
              25.605;
          } else {
            tutorial.x =
              this.vertexRect.x + (this.vertexRect.width / 100) * 100 + 15;
            tutorial.y =
              this.vertexRect.y + 17 - (110 - this.moreRect.height / 2);
          }
          break;
        case "vertex-personal-turnover-up":
          // this.$emit('unselect', this.vertex.id)

          tutorial.x = this.vertexRect.x - (124 - this.vertexRect.width / 2);
          tutorial.y = this.vertexRect.y + this.vertexRect.height;
          break;
        case "vertex-ext-menu":
          tutorial.x =
            this.vertexRect.x -
            (248 -
              (this.vertexRect.width / 100) * 76.5 -
              22.605 -
              this.moreRect.width / 2);
          tutorial.y =
            this.vertexRect.y +
            this.moreRect.height +
            (this.vertexRect.height / 100) * 2.5 +
            25.605;
          break;
        case "vertex-add-partner":
        case "vertex-add-partner-2":
        case "vertex-add-partner-3":
          tutorial.x = this.vertexRect.x - (124 - this.vertexRect.width / 2);
          tutorial.y =
            this.vertexRect.y + this.vertexRect.height + this.addRect.height;
          break;
        case "vertex-hide-tree":
        case "vertex-show-tree":
          tutorial.x = this.vertexRect.x - (124 - this.vertexRect.width / 2);
          tutorial.y =
            this.vertexRect.y + this.vertexRect.height + this.addRect.height;
          break;
        case "vertex-view-detail-info":
          if (this.app.breakpoint.mobile) {
            tutorial.x =
              this.vertexRect.x -
              (248 -
                (this.vertexRect.width / 100) * 76.5 -
                22.605 -
                this.moreRect.width / 2);
            tutorial.y =
              this.vertexRect.y +
              this.moreRect.height +
              (this.vertexRect.height / 100) * 2.5 +
              25.605;
          } else {
            tutorial.x =
              this.vertexRect.x + (this.vertexRect.width / 100) * 100 + 15;
            tutorial.y =
              this.vertexRect.y + 17 - (81 - this.moreRect.height / 2);
          }
          break;
      }
      return tutorial;
    },
  },
  mounted() {
    this.isMounted = true;

    setTimeout(() => {
      this.vertexRect = this.$refs.vertex.getBoundingClientRect();
      this.vertexContainerRect =
        this.$refs.vertexContainer.getBoundingClientRect();
      this.addRect = this.$refs.moreBtn.getBoundingClientRect();
      this.moreRect = this.$refs.addBtn.getBoundingClientRect();
    }, 100);
  },
  methods: {
    tutorialHandler(btn) {
      if (this.app.tutorialStep == "vertex-start-edit") {
        this.changeTutorialStep("vertex-start-edit-2");
      } else if (
        this.app.tutorialStep == "vertex-start-edit-2" &&
        btn == "edit"
      ) {
        this.changeTutorialStep("vertex-edit-1");
      } else if (this.app.tutorialStep == "vertex-ext-menu" && btn == "more") {
        this.changeTutorialStep("vertex-add-partner");
      } else if (
        this.app.tutorialStep == "vertex-add-partner" &&
        btn == "add"
      ) {
        this.changeTutorialStep("vertex-add-partner-2");
      } else if (
        this.app.tutorialStep == "vertex-add-partner-2" &&
        btn == "add"
      ) {
        this.changeTutorialStep("vertex-add-partner-3");
      } else if (
        this.app.tutorialStep == "vertex-add-partner-3" &&
        btn == "add"
      ) {
        setTimeout(() => {
          this.changeTutorialStep("vertex-hide-tree");
          this.$emit("unselect", this.vertex.id);
        }, 10);
      } else if (this.app.tutorialStep == "vertex-hide-tree" && btn == "hide") {
        this.changeTutorialStep("vertex-show-tree");
      } else if (this.app.tutorialStep == "vertex-show-tree" && btn == "hide") {
        setTimeout(() => {
          this.changeTutorialStep("vertex-view-detail-delay");
        }, 10);

        setTimeout(() => {
          this.changeTutorialStep("vertex-view-detail-info");
        }, 500);
      } else if (this.app.tutorialStep == "vertex-view-detail-info") {
        this.changeTutorialStep("vertex-view-detail-info-2");
      } else if (
        this.app.tutorialStep == "vertex-view-detail-info-2" &&
        btn == "edit"
      ) {
        this.changeTutorialStep("accordion-expand-helper");
      }
    },
  },
};
</script>
