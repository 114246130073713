/* eslint-disable */
import Hammer from "hammerjs";
import Breakpoints from "breakpoints-js";
import svgPanZoom from "svg-pan-zoom";

Breakpoints({
  mobile: {
      min: 0,
      max: 570
  },
  tablet: {
      min: 571,
      max: 991
  },
  desktop: {
      min: 998,
      max: Infinity
  }
})
let currentBreakpoint = Breakpoints.current();

function center(el, tree, pan){
  let width = el.width == 0 ? window.innerWidth : el.width

  return {
    x: (width / 2) - (116.33),
    y: window.innerHeight * 10 / 100,
  }
}

export default {
  install(Vue/*, options*/) {
    Vue.directive('scale-move', {
      mounted (el, { value },/* vnode, oldVnode*/) {
        el.tree = el.querySelector('.tree');

        el.panZoom = svgPanZoom(el,  {
          zoomEnabled: !(value),
          panEnabled: !(value),
          minZoom: .2,
          maxZoom: 1.5,
          zoomScaleSensitivity: 0.3,
          dblClickZoomEnabled: false,
          customEventsHandler: {
            haltEventListeners: ['touchstart', 'touchend', 'touchmove', 'touchleave', 'touchcancel'],
            init ({instance, svgElement, /*...options*/}){
              let initialScale = 1,
                  pannedX = 0,
                  pannedY = 0
              this.hammer = new Hammer(svgElement, {
                inputClass: Hammer.SUPPORT_POINTER_EVENTS ? Hammer.PointerEventInput : Hammer.TouchInput
              });
              this.hammer.get('pinch').set({enable: true});
              
              this.hammer.on('panstart panmove', (ev) => {
                if (ev.type === 'panstart') {
                  pannedX = 0
                  pannedY = 0
                }

                instance.panBy({x: ev.deltaX - pannedX, y: ev.deltaY - pannedY})
                pannedX = ev.deltaX
                pannedY = ev.deltaY
              })

              this.hammer.on('pinchstart pinchmove', (ev) => {
                if (ev.type === 'pinchstart') {
                  initialScale = instance.getZoom()
                  instance.zoomAtPoint(initialScale * ev.scale, {x: ev.center.x, y: ev.center.y})
                }
  
                instance.zoomAtPoint(initialScale * ev.scale, {x: ev.center.x, y: ev.center.y})
              })
              svgElement.addEventListener('touchmove', (e) => { e.preventDefault(); });
            },
            destroy: function(){
              this.hammer.destroy()
            }
          }
        });

        el.panZoom.resize();
        el.panZoom.fit();
        el.panZoom.center();

        el.panZoom.pan(center(el.viewBox.baseVal, el.querySelector(".svg-pan-zoom_viewport .tree").getBBox(), el.panZoom.getZoom()))
      },
      updated(el, { value },) {
        if(value) {
          if (value == "canvas-move-helper") {
            el.panZoom.enablePan()
          } else {
            el.panZoom.disablePan()
          } 
          if(value == "canvas-zoom-helper") {
            el.panZoom.enableZoom()
          } else {
            el.panZoom.disableZoom()
          }
        } else {
          el.panZoom.enablePan()
          el.panZoom.enableZoom()
        }
      },
      unmounted (el) {
        delete el.panZoom;
      }
    })
  },
}