<template>
  <div class="tutorial-layout" :class="app.tutorialStep" v-if="app.tutorialStep">
    <Popover v-if="app.tutorialStep == 'canvas-move-helper'"
      :class="this.app.tutorialStep"
      :title="this.app.locale.tutorial['canvas-move-helper-title']"
      :text="this.app.locale.tutorial['canvas-move-helper-text']"
      :isArrow="false"
    />
    <Popover v-if="app.tutorialStep == 'canvas-zoom-helper'"
      :class="this.app.tutorialStep"
      :title="this.app.locale.tutorial['canvas-zoom-helper-title']"
      :text="this.app.locale.tutorial['canvas-zoom-helper-text']"
      :isArrow="false"
    />
    <Popover v-if="app.tutorialStep == 'end'"
      :class="this.app.tutorialStep"
      :title="this.app.locale.tutorial['end-title']"
      :text="this.app.locale.tutorial['end-text']"
      :isArrow="false"
    />

    <Button class="tutorial-skip-btn" type="normal" @click="endTutorial()">
      {{
        app.tutorialStep != 'end' ? 
          app.locale.buttons.skip_tutorial : 
          app.locale.buttons.continue
      }}
    </Button>
  </div>
</template>
<script>
import "./Tutorial-layout.scss"
import { useState } from "@src/App.store.js"
import { Button, Popover } from '@components/UI'

export default {
  components: { Button, Popover },
  name: "Tutorial-layout",
  setup(){
    return useState()
  },  
}
</script>