<template>
  <svg :height="height" :width="width">
    <use
      class="show-hide-icon"
      :height="height"
      :width="width"
      :xlink:href="`#${name}`"
      :style="`opacity: 1; fill: ${color}`"
    />
  </svg>
</template>
<script>
export default {
  name: "Icon",
  props: {
    name: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: "#ffffff"
    },
    width: {
      type: String,
      default: "24",
    },
    height: {
      type: String,
      default: "24",
    },
  }
}
</script>