<template>
  <defs>
    <linearGradient id="vertexClip-gradient-0" x1="0%" y1="35%" x2="100%" y2="65%" gradientUnits="objectBoundingBox">
      <stop stop-color="#456A8A"/>
      <stop offset="1" stop-color="#034580"/>
    </linearGradient>
    <linearGradient id="vertexClip-gradient-3" x1="0%" y1="35%" x2="100%" y2="65%" gradientUnits="objectBoundingBox">
      <stop stop-color="#FF7AA2"/>
      <stop offset="1" stop-color="#E2678C"/>
    </linearGradient>  
    <linearGradient id="vertexClip-gradient-6" x1="0%" y1="35%" x2="100%" y2="65%" gradientUnits="objectBoundingBox">
      <stop stop-color="#FF6069"/>
      <stop offset="1" stop-color="#FF8289"/>
    </linearGradient>  
    <linearGradient id="vertexClip-gradient-9" x1="0%" y1="35%" x2="100%" y2="65%" gradientUnits="objectBoundingBox">
      <stop offset="15.63%" stop-color="#E98C37"/>
      <stop offset="100%" stop-color="#FFC56F"/>
    </linearGradient>      
    <linearGradient id="vertexClip-gradient-12" x1="0%" y1="35%" x2="100%" y2="65%" gradientUnits="objectBoundingBox">
      <stop offset="15.63%" stop-color="#00D689"/>
      <stop offset="100%" stop-color="#00A861"/>
    </linearGradient>  
    <linearGradient id="vertexClip-gradient-15" x1="0%" y1="35%" x2="100%" y2="65%" gradientUnits="objectBoundingBox">
      <stop offset="15.63%" stop-color="#00D4E3"/>
      <stop offset="100%" stop-color="#6599FF"/>
    </linearGradient>    
    <linearGradient id="vertexClip-gradient-18" x1="0%" y1="35%" x2="100%" y2="65%" gradientUnits="objectBoundingBox">
      <stop stop-color="#0052D4"/>
      <stop offset="0.5" stop-color="#4364F7"/>
      <stop offset="1" stop-color="#37BAF2"/>
    </linearGradient>  
    <linearGradient id="vertexClip-gradient-21" x1="0%" y1="35%" x2="100%" y2="65%" gradientUnits="objectBoundingBox">
      <stop stop-color="#4776E6"/>
      <stop offset="1" stop-color="#8E54E9"/>
    </linearGradient>

    <marker 
      id="arrowCap" 
      viewBox="0 0 30 19"
      markerHeight="15" 
      markerWidth="10"
      refX="15"
      refY="16"
      orient="0"
      fill="none" 
    >
      <path
        stroke="#B1B7CF" 
        stroke-width="3" 
        stroke-linecap="round" 
        stroke-linejoin="round"
        fill="none" 
        d="M4 5 15 16 26 5"
      />
    </marker>

    <clipPath id="edit-header" clipPathUnits="objectBoundingBox">
      <!-- <path 
        d="M506 19.1477C506 17.8328 504.745 16.8786 503.433 16.9682C503.125 16.9893 502.814 17 502.5 17C495.044 17 489 10.9558 489 3.5C489 3.18624 489.011 2.87498 489.032 2.56657C489.121 1.25476 488.167 0 486.852 0H3C1.34315 0 0 1.34315 0 3V87C0 88.6569 1.34314 90 3 90H503C504.657 90 506 88.6569 506 87V19.1477Z"
      /> -->
      <path 
        d="M1,0.213 C1,0.198,0.998,0.188,0.995,0.189 C0.994,0.189,0.994,0.189,0.993,0.189 C0.978,0.189,0.966,0.122,0.966,0.039 C0.966,0.035,0.966,0.032,0.966,0.029 C0.967,0.014,0.965,0,0.962,0 H0.006 C0.003,0,0,0.015,0,0.033 V0.967 C0,0.985,0.003,1,0.006,1 H0.994 C0.997,1,1,0.985,1,0.967 V0.213"
      />
    </clipPath>

    <clipPath id="edit-header-mobile" clipPathUnits="objectBoundingBox">
      <!-- <path d="M260 19.1477C260 17.8328 258.745 16.8786 257.433 16.9682C257.125 16.9893 256.814 17 256.5 17C249.044 17 243 10.9558 243 3.5C243 3.18624 243.011 2.87498 243.032 2.56657C243.121 1.25476 242.167 0 240.852 0H3C1.34315 0 0 1.34315 0 3V58C0 59.6569 1.34314 61 3 61H257C258.657 61 260 59.6569 260 58V19.1477Z"/> -->
      <path d="M1,0.314 C1,0.292,0.995,0.277,0.99,0.278 C0.989,0.279,0.988,0.279,0.987,0.279 C0.958,0.279,0.935,0.18,0.935,0.057 C0.935,0.052,0.935,0.047,0.935,0.042 C0.935,0.021,0.931,0,0.926,0 H0.012 C0.005,0,0,0.022,0,0.049 V0.951 C0,0.978,0.005,1,0.012,1 H0.988 C0.995,1,1,0.978,1,0.951 V0.314"/>
    </clipPath>

  </defs>

  <symbol id="percentSymbol" width="16" height="15" viewBox="0 0 16 15" >
    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.5 6C5.15688 6 6.5 4.65685 6.5 3C6.5 1.34315 5.15688 0 3.5 0C1.84312 0 0.5 1.34315 0.5 3C0.5 4.65685 1.84312 6 3.5 6ZM3.5 4.5C4.32841 4.5 5 3.82843 5 3C5 2.17157 4.32841 1.5 3.5 1.5C2.67159 1.5 2 2.17157 2 3C2 3.82843 2.67159 4.5 3.5 4.5Z"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5 15C14.1569 15 15.5 13.6569 15.5 12C15.5 10.3431 14.1569 9 12.5 9C10.8431 9 9.5 10.3431 9.5 12C9.5 13.6569 10.8431 15 12.5 15ZM12.5 13.5C13.3284 13.5 14 12.8284 14 12C14 11.1716 13.3284 10.5 12.5 10.5C11.6716 10.5 11 11.1716 11 12C11 12.8284 11.6716 13.5 12.5 13.5Z"/>
    <path d="M13.25 0H15.5L2.75 15H0.5L13.25 0Z" fill="white"/>
  </symbol>
</template>

<script>
  export default {
    name: 'VertexDefs',
  }
</script>