<template>
  <div 
    :class="[{
      'tutorial-mode': app.tutorialStep
    }, 'popup-container']"
  ></div>
</template>
<script>
  import "./Popup.scss"
  import { useState } from "@src/App.store.js"

  export default {
    name: "PopupContainer",
    setup(){
      return useState()
    },
  }
</script>
