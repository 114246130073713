// let testJWT = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiSm9obiBEb2UiLCJhYm9fbnVtYmVyIjoxNTE2MjM5MDIyfQ.7PZ6CNSiEs6aatlYZka92UnZpjc5CdxtzA3a6TZHAhI' //https://jwt.io/#debugger-io?token=

// window.localStorage.setItem("token", testJWT);

import Fetch from "@src/API/Fetch.js"

export default async function GetUser() {
  let result = await Fetch("GET", "auth")
  
  return result;
}