/* eslint-disable */

import Breakpoints from "breakpoints-js";
import { reactive, inject, readonly } from "vue";
import locales from "@src/locales";
import { defaultTree, calcTree as CalcTree } from "@src/utils/Calc.js";

import { GetUser, Load, Save, DownloadPDF } from "@src/API";

Breakpoints({
  mobile: {
    min: 0,
    max: 570,
  },
  tablet: {
    min: 571,
    max: 991,
  },
  desktop: {
    min: 998,
    max: Infinity,
  },
});

export const stateSymbol = Symbol("app");

export const createState = (_state = { locale: "ru" }) => {
  let currentBreakpoint = Breakpoints.current();
  let state = reactive({
    locale: locales[_state.locale],
    calculator: {
      country: null,
      clientType: null,
      total: 0,
      date: null,
    },
    breakpoint: {
      mobile: currentBreakpoint?.name == "mobile",
      tablet: currentBreakpoint?.name == "tablet",
      desktop: currentBreakpoint?.name == "desktop",
    },
    Tree: defaultTree(),
    tutorialStep:
      window.localStorage.getItem("tutorialMode") == "null" ? null : "country",
    currentPopup: null,
    saved: false,
  });

  GetUser().then((user) => (state.user = user));

  const changeLocale = (code) => {
    state.locale = locales[code];
    state.saved = false;
  };
  const changeCountry = (country) => {
    state.calculator.country = country;
    state.saved = false;
  };

  const changeClientType = (clientType) => {
    state.calculator.clientType = clientType;

    // add 100 points by default for VIP client type
    state.Tree.personal_value = clientType === "VIP" ? 100 : 0;

    state.saved = false;
  };

  const changeTutorialStep = (step) => {
    if (state.tutorialStep) state.tutorialStep = step;
  };

  const startTutorial = () => {
    window.localStorage.removeItem("tutorialMode");
    window.location.reload();
  };

  const endTutorial = () => {
    window.localStorage.setItem("tutorialMode", "null");
    state.tutorialStep = null;
  };

  const setcurrentPopup = (modal) => (state.currentPopup = modal);

  const calcTree = () => {
    CalcTree(state.Tree, {
      BO: state.calculator.country,
      business_nature: state.calculator.clientType,
    });

    const calculateTotalByPlan = (planObject) => {
      Object.values(planObject).forEach(
        (value) =>
          (state.calculator.total = Math.round(state.calculator.total + value))
      );
    };
    state.Tree.businessNature = state.calculator.clientType;

    state.saved = false;

    state.calculator.total = 0;

    calculateTotalByPlan(state.Tree.amway_plan);
    calculateTotalByPlan(state.Tree.business_plus);

    if (state.Tree.personal_sells_amount) {
      state.calculator.total += state.Tree.personal_sells_amount;
    }

    if (state.Tree.personal_friends_buy) {
      state.calculator.total += state.Tree.personal_friends_buy;
    }

    if (state.calculator.clientType === "VIP") {
      state.Tree.flag_bronze_start = false;
      state.Tree.flag_bronze_bonus = false;
      state.Tree.hideBronze = true;
    }
  };

  const saveTree = async () => {
    let isNew = !Boolean(state.calculator.date);

    let [tree, meta] = await Save(
      state.Tree,
      {
        country: state.calculator.country,
        clientType: state.calculator.clientType,
        total: state.calculator.total,
        // date: state.calculator.date,
      },
      isNew
    );

    // if(!state.calculator.date) state.calculator.date;
    state.calculator = meta;
    Object.assign(state.Tree, tree);

    state.saved = true;

    let user = await GetUser();
    state.user = user;
    return [tree, meta];
  };

  const loadTree = async (id) => {
    let [tree, meta] = await Load(id);

    meta.total = meta.total.toLocaleString("ru-RU");

    state.saved = true;

    state.calculator = meta;

    // switch(meta.country){
    //   case 88:
    //     state.locale = locales['ru']
    //   break;
    //   case 517:
    //     state.locale = locales['kz']
    //   break;
    // }

    Object.assign(state.Tree, tree);
    calcTree();
  };

  const downloadPDF = async () => {
    if (!state.saved) await saveTree();

    DownloadPDF(state.Tree.id, state.Tree.name);
  };

  const Reset = async (id) => {
    // window.location.reload();
    state.currentPopup.openSwitch(false);
    document.querySelector(".popup-container").classList.toggle("show", false);
    state.locale = locales[_state.locale];

    Object.assign(state.Tree, defaultTree());

    state.saved = false;

    state.calculator = {
      country: null,
      clientType: null,
      total: 0,
      date: null,
    };
  };

  Breakpoints.on("mobile tablet desktop", "enter", (...a) => {
    currentBreakpoint = Breakpoints.current();

    state.breakpoint.mobile = currentBreakpoint.name == "mobile";
    state.breakpoint.tablet = currentBreakpoint.name == "tablet";
    state.breakpoint.desktop = currentBreakpoint.name == "desktop";
  });

  return {
    changeLocale,
    changeCountry,
    changeClientType,

    changeTutorialStep,
    startTutorial,
    endTutorial,

    setcurrentPopup,
    calcTree,

    saveTree,
    loadTree,
    downloadPDF,

    Reset,

    app: readonly(state),
    Tree: state.Tree,
    // breakpoint
  };
};

export const useState = () => inject(stateSymbol);
