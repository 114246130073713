import rewardTypes from "@/constants/rewardTypes";
export const haveSponsorReward = (item) => {
  const leveledChildren = item.children.filter((child) => child.level === 21);
  // генераторы - айтемы от которых будет считаться сумма для награды
  let generators = [];
  let resultGenerator;
  const recursiveReward = leveledChildren.map((child) => {
    const result = haveSponsorReward(child);

    // если ребенок имеет попечительское, заменяем генератор с его дочерних, на него самого
    if (!result.useGenerators) {
      result.generators = [
        {
          ...result,
          item: child,
        },
      ];
    }

    return result;
  });

  const withGuard =
    item.flag_guardianship || recursiveReward.some((child) => child.withGuard);

  const guardChildren = recursiveReward.filter(
    (child) =>
      child.item.flag_guardianship || (child.withGuard && !child.haveReward)
  );

  recursiveReward.forEach((child) => {
    if (child.generators?.length) {
      generators = [...generators, ...child.generators];
    } else {
      if (child.flag_guardianship || child.withGuard) {
        generators = [...generators, child];

        child.item.children?.forEach((innerChildren) => {
          if (!innerChildren.flag_guardianship) {
            generators = [
              ...generators,
              { item: innerChildren, notChangeLeader: true },
            ];
          }
        });
      }
    }
  });

  if (generators?.length) {
    resultGenerator = generators;
  } else {
    resultGenerator = guardChildren;
  }

  if (
    (item.leader_level === 21 && guardChildren.length >= 1) ||
    (item.businessNature === "ABO" &&
      guardChildren.length >= 1 &&
      leveledChildren.filter((child) => !child.flag_guardianship).length)
  ) {
    return {
      haveReward: true,
      type: rewardTypes.FULL,
      item,
      useGenerators: false,
      withGuard,
      generators: resultGenerator,
    };
  } else if (item.leader_level <= 21 && guardChildren.length >= 2) {
    return {
      haveReward: true,
      type: rewardTypes.PART,
      item,
      useGenerators: false,
      withGuard,
      generators: resultGenerator,
    };
  } else {
    return {
      haveReward: false,
      type: rewardTypes.NONE,
      item,
      useGenerators: true,
      withGuard,
      generators: resultGenerator,
    };
  }
};
