/* eslint-disable */

/**
  {
    id	string($uuid)
    is_root	boolean
    name	string

    personal_value
    client_value
    personal_points
    group_points

    level*	number($float)
    level_difference_reward	number($float)

    sponsor_status	boolean

    flag_guardianship, 
    flag_bronze_start,
    flag_bronze_bonus,

    sponsor_reward	number($float)
    leader_reward	number($float)

    personal_achievements	number($float)
    personal_percent_difference	number($float)

    retail_margin	number($float)

    plan_leader_reward	number($float)
    plan_sponsor_reward	number($float)

    ruby_reward	number($float)
    pearl_reward	number($float)

    friends_discount	number($float)
    bronze_start	number($float)
    bronze_reward	number($float)
    elite_plus	number($float)

    active_discounts	number($float)

    children	[...]
  } 
*/
async function ModelFormat(tree, meta) {
  let out = {};

  let format = async (branch, current) => {
    Object.assign(current, {
      id: branch.id,
      is_root: branch.root,
      name: branch.name,

      personal_value: branch.personal_value,
      client_value: branch.client_value,
      personal_points: branch.personal_points,
      group_points: branch.group_points,

      level: branch.level,
      level_difference_reward: branch.level_difference_reward,

      flag_guardianship: branch.flag_guardianship,
      flag_bronze_bonus: branch.flag_bronze_start,
      flag_bronze_start: branch.flag_bronze_bonus,

      sponsor_reward: branch.sponsor_reward,
      leader_reward: branch.leader_reward,

      //amway_plan
      personal_achievements: branch.personal_achievements,
      personal_percent_difference: branch.amway_plan
        ? branch.amway_plan.personal_percent_difference
        : undefined,
      retail_margin: branch.amway_plan
        ? branch.amway_plan.retail_margin
        : undefined,
      plan_leader_reward: branch.amway_plan
        ? branch.amway_plan.leader_reward
        : undefined,
      plan_sponsor_reward: branch.amway_plan
        ? branch.amway_plan.sponsor_reward
        : undefined,
      ruby_reward: branch.amway_plan
        ? branch.amway_plan.ruby_reward
        : undefined,
      pearl_reward: branch.amway_plan
        ? branch.amway_plan.pearl_reward
        : undefined,

      //business_plus
      friends_discount: branch.business_plus
        ? branch.business_plus.friends_discount
        : undefined,
      bronze_start: branch.business_plus
        ? branch.business_plus.bronze_start
        : undefined,
      bronze_reward: branch.business_plus
        ? branch.business_plus.bronze_reward
        : undefined,
      elite_plus: branch.business_plus
        ? branch.business_plus.elite_plus
        : undefined,

      personal_friends_buy: branch.personal_friends_buy,
      personal_sells_amount: branch.personal_sells_amount,

      own_children: branch.own_children,
      total_children: branch.total_children,

      total: meta.total,
      country: meta.country,
      client_type: meta.clientType,

      children: new Array(branch.children.length),
    });

    for (let i = 0; i < branch.children.length; i++) {
      current.children[i] = {};
      await format(branch.children[i], current.children[i]);
    }
  };

  await format(tree, out);

  return out;
}

async function TreeFormat(tree) {
  let out = {};

  let format = async (branch, current) => {
    Object.assign(current, {
      id: branch.id,
      root: branch.is_root,
      name: branch.name,

      personal_value: branch.personal_value,
      client_value: branch.client_value,
      personal_points: branch.personal_points,
      group_points: branch.group_points,

      level: branch.level,
      leader_level: branch.leader_level,
      leader_value: branch.leader_value,
      level_difference_reward: branch.level_difference_reward,

      flag_guardianship: branch.flag_guardianship,
      flag_bronze_bonus: branch.flag_bronze_start,
      flag_bronze_start: branch.flag_bronze_bonus,

      sponsor_reward: branch.sponsor_reward,
      leader_reward: branch.leader_reward,

      //amway_plan
      amway_plan: {
        personal_achievements: branch.personal_achievements,
        personal_percent_difference: branch.personal_percent_difference,
        retail_margin: branch.retail_margin,
        leader_reward: branch.plan_leader_reward,
        sponsor_reward: branch.plan_sponsor_reward,
        ruby_reward: branch.ruby_reward,
        pearl_reward: branch.pearl_reward,
      },

      //business_plus
      business_plus: {
        friends_discount: branch.friends_discount,
        bronze_start: branch.bronze_start,
        bronze_reward: branch.bronze_reward,
        elite_plus: branch.elite_plus,
      },

      active_discounts: branch.active_discounts,

      date: branch.date,

      children: new Array(branch.children.length),
    });

    for (let i = 0; i < branch.children.length; i++) {
      current.children[i] = {};
      await format(branch.children[i], current.children[i]);
    }
  };

  await format(tree, out);

  return [
    out,
    {
      total: tree.total,
      date: new Date(tree.updated_at),
      country: tree.country,
      clientType: tree.client_type,
    },
  ];
}

export default async function transform(type = "model", data) {
  if (type == "model") {
    return ModelFormat(...data);
  } else if (type == "tree") {
    return TreeFormat(data);
  }
}
