<template>
  <svg
    :class="[
      {
        'tutorial-mode': tutorialState,
      },
      'diagram-canvas',
      app.tutorialStep,
    ]"
    v-scale-move="app.tutorialStep"
    ref="canvas"
    :viewBox="viewBox"
  >
    <!--
    :height="'100%'"
    :width="'100%'"  -->
    <Tree ref="tree" />
    <VertexDefs />
  </svg>
</template>

<script>
import { /*Vertex,*/ VertexDefs } from "@components/Diagram/Vertex";
import { useState } from "@src/App.store.js";
import Tree from "../Tree";
import "./Canvas.scss";

export default {
  name: "Canvas",
  components: {
    // Vertex,
    VertexDefs,
    Tree,
  },
  data() {
    return {
      test: 0,
      viewport: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
    };
  },
  setup() {
    return useState();
  },
  computed: {
    viewBox() {
      const { height, width } = this.$refs.canvas
        ? this.$refs.canvas.getBoundingClientRect()
        : {
            width: this.viewport.width,
            height: this.viewport.height,
          };

      const viewBox = {
        x: 0,
        y: 0,
        height,
        width,
        // width: this.viewport.width,
        // height: this.viewport.height,

        toString() {
          return `${this.x} ${this.y} ${this.width} ${this.height}`;
        },
      };

      return viewBox;
    },
    tutorialState() {
      return (
        this.app.tutorialStep == "vertex-start-edit" ||
        this.app.tutorialStep == "vertex-personal-turnover-up" ||
        this.app.tutorialStep == "vertex-ext-menu" ||
        this.app.tutorialStep == "vertex-add-partner" ||
        this.app.tutorialStep == "vertex-add-partner-2" ||
        this.app.tutorialStep == "vertex-add-partner-3" ||
        this.app.tutorialStep == "vertex-view-detail-delay" ||
        this.app.tutorialStep == "vertex-view-detail-info" ||
        this.app.tutorialStep == "vertex-hide-tree" ||
        this.app.tutorialStep == "vertex-show-tree" ||
        this.app.tutorialStep == "vertex-open-edit"
      );
    },
  },
  mounted() {
    this.updateViewPort();

    window.addEventListener("resize", this.updateViewPort);
  },
  unmounted() {
    window.removeEventListener("resize", this.updateViewPort);
  },
  methods: {
    updateViewPort() {
      if (!this.$refs.canvas) return;
      const { height, width } = this.$refs.canvas.getBoundingClientRect();

      this.viewport = {
        height,
        width,
      };
    },
  },
};
</script>
