/* eslint-disable */
import { v4 as uuid } from "uuid";
import sponsor from "@/utils/calculations/sponsor";
import pearl from "@/utils/calculations/pearl";
import leaderReward from "@/utils/calculations/leaderReward";
import { percentLevel } from "@/utils/calculations/percentLevel";
import { calcLeaderForSegment } from "@/utils/helpers/leader";

export function defaultTree() {
  return {
    id: uuid(),

    flag_guardianship: false,
    flag_bronze_start: true,
    flag_bronze_bonus: true,

    personal_value: 100,
    client_value: 0,
    personal_points: 100,
    group_points: 100,

    level: 0,
    leader_level: 0,
    // max_level: 0,
    level_difference_reward: 0,
    leader_value: 0,
    leader_reward: 0,
    sponsor_reward: 0,

    own_children: 0,
    total_children: 0,

    amway_plan: {},
    business_plus: {},

    name: "",

    root: true,

    children: [],
  };
}

export function calcTree(tree, options) {
  let counter21Plus = 0;
  let is_bronze_start = false;
  let is_bronze_bonus = false;
  const BO_KZ = 710;
  const VAT_KZ = 1.12;
  const SB_KZ = 0.12;
  const recursiveCalc = (item) => {
    if (item.children.length) {
      item.children.forEach((child) => recursiveCalc(child));
    }

    let plus21InChildren = item.children.find((element) => {
      return element.level >= 21;
    });

    item.personal_points = item.personal_value + (item.client_value || 0);

    const childrenForGroupPoints = item.children.filter(
      (i) => i.group_points < 10000 && i.level < 21
    );

    if (childrenForGroupPoints.length) {
      item.group_points =
        item.personal_points +
        childrenForGroupPoints.reduce(
          (sum, currentItem) => sum + currentItem.group_points,
          0
        );
    } else {
      item.group_points = item.personal_points;
    }

    item.level = percentLevel(item.group_points);

    if ((item.root && counter21Plus > 0) || plus21InChildren) {
      item.level = 21;
    }

    // Подсчет возраграждения за разницу в уровне (для последнего уровня)
    if (item.children.length) {
      item.children.forEach((c) => {
        c.level_difference_reward =
          item.level > c.level
            ? ((item.level - c.level) / 100) * c.group_points * options.BO
            : 0;
      });
    }
    // Лидерские баллы

    calcLeaderForSegment(item);

    // Лидерский уровень
    item.leader_level = percentLevel(item.leader_value);

    if (
      item.children.some(
        (child) => child.level >= 21 && !child.flag_guardianship
      ) &&
      item.businessNature === "ABO"
    ) {
      item.leader_level = 21;
    }

    // Подсчет попечительского вознаграждения
    if (item.flag_guardianship && item.level === 21 && !item.root) {
      item.sponsor_reward = item.group_points * options.BO * 0.04;
      item.leader_reward = 0;
    }

    if (!item.flag_guardianship && item.level === 21 && !item.root) {
      // Подсчет лидерского вознаграждения
      item.sponsor_reward = 0;
      item.leader_reward = item.group_points * options.BO * 0.06;
    }
    if (item.level === 21) {
      counter21Plus += 1;
    }
    // // "Бронзовое Вознаграждение" – добавить проверку уровней нижестоящих чуваков (3 ветки не менее 6% каждая)

    let counter3plus = 0;
    let counter6plus = 0;

    for (let branch of item.children) {
      if (branch.level >= 3) counter3plus++;
      if (branch.level >= 6) counter6plus++;
    }
    if (counter3plus >= 3) is_bronze_start = true;
    if (counter6plus >= 3) is_bronze_bonus = true;

    if (item.children.length) {
      item.own_children = item.children.length;
      item.total_children =
        item.own_children +
        item.children.reduce((sum, child) => {
          if (child.own_children >= 0) {
            return sum + child.own_children;
          }

          return sum;
        }, 0);
    }
  };
  recursiveCalc(tree);

  // Подсчет всех вознаграждений структуры
  // let tree = {amway_plan: {}, business_plus: {}};
  // за личные достижения

  tree.amway_plan.personal_achievements =
    tree.personal_points * options.BO * (tree.level / 100);

  // за разницу в процентах
  if (tree.children.length) {
    tree.amway_plan.personal_percent_difference = tree.children
      .map((c) => c.level_difference_reward)
      .reduce((c1, c2) => c1 + c2);
  } else {
    tree.amway_plan.personal_percent_difference = 0;
  }

  // Лидерское вознаграждение
  tree.amway_plan.leader_reward = leaderReward(tree, BO_KZ);

  if (tree.amway_plan.leader_reward < 0) {
    tree.amway_plan.leader_reward = 0;
  }

  console.log(
    tree.leader_level,
    tree.amway_plan.leader_reward,
    tree.leader_value
  );

  // Попечительское вознаграждение
  tree.amway_plan.sponsor_reward = sponsor(tree, BO_KZ);

  // Рубиновое вознаграждение
  tree.amway_plan.ruby_reward =
    tree.group_points >= 20000 ? tree.group_points * options.BO * 0.02 : 0;

  // Жемчужное вознаграждение (не понятно)
  tree.amway_plan.pearl_reward = pearl(tree, BO_KZ);

  // tree.business_plus.bronze_start /= 100;
  if (tree.business_nature !== "VIP") {
    // Бронзовый Старт+
    const amwayTotal =
      tree.amway_plan.personal_achievements +
      tree.amway_plan.personal_percent_difference;

    tree.business_plus.bronze_start =
      tree.flag_bronze_start &&
      tree.level >= 9 &&
      tree.personal_value + tree.client_value >= 200 &&
      tree.children.filter((c) => c.level >= 3).length >= 3
        ? amwayTotal * 0.2
        : 0;
    // Бронзовая Выплата+
    tree.business_plus.bronze_reward =
      tree.flag_bronze_bonus &&
      tree.level >= 15 &&
      tree.children.filter((c) => c.level >= 6).length >= 3 &&
      tree.personal_value + tree.client_value >= 200
        ? amwayTotal * 0.3
        : 0;
    // tree.business_plus.bronze_reward /= 100;
    // Элит+
    tree.business_plus.elite_plus =
      options.business_nature === "ABO" &&
      tree.level === 21 &&
      tree.group_points >= 16500 &&
      tree.personal_points >= 200
        ? tree.group_points * 0.02 * options.BO
        : 0;
  }

  // tree.business_plus.elite_plus /= 100;

  // + За личные покупки и покупки друзей VIP

  if (options.business_nature === "VIP") {
    tree.personal_friends_buy = Math.floor(tree.personal_points / 100) * 10000;
  }

  // + За персональный объем продаж

  if (options.business_nature === "ABO") {
    tree.personal_sells_amount = Math.floor(
      tree.personal_points * BO_KZ * VAT_KZ * 1.103896 * SB_KZ
    );
  }

  if (options.business_nature === "VIP") {
    delete tree.amway_plan.leader_reward;
    delete tree.amway_plan.pearl_reward;
    delete tree.amway_plan.ruby_reward;
  }
  delete tree.amway_plan.sponsor_reward;
  return tree;
}
