<template>
  <div 
    :class="['popover', !isArrow ? 'pos-center' : `pos-${arrowPosition}`]"
    :style="{ left: `${x}px`, top: `${y}px` }"
    ref="popover"
  >
    <div v-if="title" 
      class="popover-title"
      v-html="title"
    >
    </div>
    <div v-if="text" 
      class="popover-text"
      v-html="text"
    >
    </div>
    
    <div v-if="isArrow" 
      :class="[`arrow`, `pos-${arrowPosition}`, `align-${arrowAlign}`, {

      }]"
      :style="{
        top: `${arrowTop}px`
      }"
    ></div>
  </div>
</template>
<script>
import "./Popover.scss"

export default {
  name: "Popover",
  props: {
    title: {
      type: String,
    },
    text: {
      type: String,
    },
    isArrow: {
      type: Boolean,
      default: false
    },
    arrowPosition: {
      type: String,
      default: "bottom"
    },
    arrowAlign: {
      type: String,
      default: 'center'
    },
    x: {
      // type: Number
    },
    y: {
      // type: Number
    },
    // position: {
    //   type: String
    // }
  },
  data() {
    return {
      arrowTop: null,
    }
  },
  mounted() {
    let rect = this.$refs.popover.getBoundingClientRect()

    if(this.arrowPosition == "left" || this.arrowPosition == "right"){
      if(this.arrowAlign == "center"){
        this.arrowTop = (rect.height / 2) - (21.21 / 2)
      }      
    } else this.arrowTop = undefined
  }
}
</script>