import { downloadBlob } from "download.js"

export const baseUrl = new URL("https://calculator.amwayservices.ru/api/v1/");

async function authCheck(headers) {
  let token = window.location.hash.replace("#", "") //window.localStorage.getItem("token");
  if (!token) return;
  
  headers['Authorization'] = `Bearer ${token}`;
  return;
}

export default async function Fetch(method = "GET", url, data, headers = {}, download = false) {
  if(!url) throw 'укажите url';
  
  let body = method != "GET" && data ? JSON.stringify(data) : null;
  let reqHeaders = {
    // ...baseHeaders,
    ...headers
  }

  if(method != "GET" && !download) reqHeaders['Content-Type'] = 'application/json';

  await authCheck(reqHeaders);

  let response = await fetch(new URL(url, baseUrl), {
    method,
    body,
    mode: 'cors',
    headers: reqHeaders
  })

  if(response.ok) {
    let resType = response.headers.get('Content-Type');

    if(/json/i.test(resType)) {
      return (await response.json()).result
    } else if(download) {
      downloadBlob(download, await response.blob());
    } else {
      return await response.text()
    }    
  } else {
    throw 'Failed'
  }
}