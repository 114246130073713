<template>
  <div
    :class="[{
      rolled: rolled
    },'accordion-container']"
  >
    <!-- <transition name="list-in"> -->
      <div class="list">
        <!-- -->
        <div
          v-for="(element) in list"
          :key="element.label"
          class="list-element"
        >
          <div class="label">
            {{element.label}}
          </div>
          <div class="value">
            {{element.value}}
          </div>
        </div>
      </div>
    <!-- </transition> -->

    <div class="preview" v-if="rolled" @click="expandSwitch(false)">
      <div class="label">
        {{list[list.length - 1].label}}
      </div>
      <div class="value">
        {{list[list.length - 1].value}}
      </div>
    </div>
    <div class="hider" v-if="!rolled" @click="expandSwitch(true)">
      {{app.locale.accord_hide}}
    </div>
    <Icon
      :class="['accordion-arrow']"
      name="accordion-arrow"
      width="14px"
      height="7px"
      @click="expandSwitch(!rolled)"
    />

    <Popover v-if="tutorial && !(app.tutorialStep == 'accordion-collapse-helper' && rolled)"
      :class="app.tutorialStep"
      :title="tutorial.title"
      :text="tutorial.text"
      :isArrow="true"
      arrow-align="left"
    />
  </div>
</template>
<script>
import { Icon, Popover } from "@components/UI"
import { useState } from "@src/App.store.js"
import "./Accordion.scss"

export default {
  name: "Accordion",
  components: {
    Icon, Popover
  },
  props: {
    list: {
      type: Array,
      requied: true,
    },
    expandBlock: {
      default: false
    },
    tutorial: {}
  },
  setup(){
    return useState()
  },
  data() {
    return {
      rolled: true
    }
  },
  methods: {
    expandSwitch(rolled) {
      if(!this.expandBlock) {
        this.rolled = rolled;
        this.$emit('expand', !rolled)
      }
    }
  }
}
</script>
