<template>
  <Layout :showFooter="page == 'start'">
    <transition name="fade-start">
      <Start v-if="page == 'start'" :class="{fade: isCalc}"/>
    </transition>


    <transition name="fade-calc">
      <Calculator v-if="page == 'calc'"/>
    </transition>
    <PopupContainer />

    <Tutorial />
  </Layout>
</template>

<script>
import Layout from "@/components/UI/Layout/Layout";
import Start from "@/pages/start";
import Calculator from "@/pages/calculator";
import PopupContainer from "@/components/UI/Popup/Popup-container";
import Tutorial from "@/components/UI/Tutorial-layout/Tutorial-layout";
import { useState } from "@src/App.store.js"

export default {
  name: "Main",
  components: {Tutorial, PopupContainer, Calculator, Start, Layout},
  data() {
    return {
      page: 'start',
    }
  },
  setup(){
    return useState()
  },
  computed: {
    isCalc() {
      return Boolean(this.app.calculator.clientType) && Boolean(this.app.calculator.country)
    }
  },
  watch: {
    isCalc() {
      if(this.isCalc)
        setTimeout(()=>{
          this.page = 'calc'
        }, 500)
      else
          // setTimeout(()=>{
        this.page = "start"
      // }, 500)
    }
  }
}
</script>

<style scoped>

</style>