<template>
  <div class="inline-container">
    <p 
      v-if="invitation" 
      class="invitation" 
      v-html="invitation"
    />

    <div class="inline-select">
      <div
        v-for="(option) in options"
        class="option"
        :class="value == option.code ? 'active' : 'inactive'"
        :key="option.code"
        @click="$emit('change', option.code)"
      >
        {{option.label}}
      </div>
    </div>

    <Popover v-if="tutorial"
      :title="tutorial.title"
      :text="tutorial.text"
      :isArrow="true"
    />
  </div>
</template>
<script>
import { Popover } from "@components/UI"
import "./InlineSelect.scss"

export default {
  name: "InlineSelect",
  components: { 
    Popover
  },
  props: {
    options: {
      type: Array,
      required: true
    },
    value: {
      required: true
    },
    invitation: {
      type: String,
      default: null
    },

    tutorial: {
      default: null
    }
  }
}
</script>