<template>
  <div 
    :class="['layout', {
      'tutorial-mode': app.tutorialStep
    }]"
  >
    <main>
      <slot />
    </main>
  </div>
</template>
<script>
import { useState } from "@src/App.store.js"
import "./Layout.scss"

export default {
  name: "Layout",
  setup(){
    return useState()
  },
  props: {
    showFooter: {
      type: Boolean,
      default: true
    }
  }
}
</script>