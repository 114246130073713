export function getPearlItems(currentItem) {
  const pearlChildren = currentItem.children.filter(
    (child) => child.level >= 21
  );
  const fullPearlAmount = !pearlChildren.some(
    (child) => child.group_points < 10000
  );
  const withSecondLevelItems = pearlChildren.filter(
    (child) =>
      child.children.length && child.children.some((item) => item.level >= 21)
  );
  const hasPearl = pearlChildren.length >= 3 && !!withSecondLevelItems.length;

  return {
    pearlChildren,
    fullPearlAmount,
    withSecondLevelItems,
    hasPearl,
  };
}

export function calcPearlSum(item, isFirstLine, pearlChild) {
  if (item.level < 21) return 0;

  const { hasPearl } = getPearlItems(item);
  let sum = 0;

  sum += isFirstLine ? 0 : item.group_points;

  if (item.children.length && !pearlChild) {
    return item.children.reduce(
      (currentValue, child) =>
        (currentValue += calcPearlSum(child, false, hasPearl)),
      sum
    );
  }

  if (pearlChild && sum < 10000) {
    sum = 10000;
  }

  return sum;
}
