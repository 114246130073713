import rewardTypes from "@/constants/rewardTypes";

export const calcLeaderForSegment = (item) => {
  let leaderValue = item.group_points;

  item.children.forEach((child) => {
    if (child.level >= 21) {
      calcLeaderForSegment(child);
    }
  });

  leaderValue += item.children.reduce((sum, child) => {
    if (child.leader_level < 21 && child.flag_guardianship) {
      return sum + child.leader_value;
    }

    return sum;
  }, 0);

  item.leader_value = leaderValue;
};

export const calcLeaderForReward = (item) => {
  if (item.group_points >= 4000 && item.group_points < 10000) {
    if (item.level >= 21) {
      return 10000;
    }

    return 0;
  } else if (item.group_points >= 10000) {
    return item.group_points;
  } else {
    let sum = item.level >= 21 ? item.group_points : 0;

    item.children.forEach((child) => {
      sum += calcLeaderForReward(child);
    });

    return sum;
  }
};

export const haveLeaderReward = (item) => {
  const leveledChildren = item.children.filter((child) => child.level === 21);
  // генераторы - айтемы от которых будет считаться сумма для награды
  let generators = [];
  let resultGenerator;
  const recursiveReward = leveledChildren.map((child) => {
    const result = haveLeaderReward(child);

    // если ребенок имеет лидерское, заменяем генератор с его дочерних, на него самого
    if (!result.useGenerators) {
      result.generators = [
        {
          ...result,
          item: child,
        },
      ];
    }

    return result;
  });

  const noGuardChildren = recursiveReward.filter(
    (child) => !child.item.flag_guardianship
  );

  noGuardChildren.forEach((child) => {
    if (child.haveReward) {
      generators = [...generators, child];
    } else {
      generators = [...generators, ...child.generators, child];
    }
  });

  if (generators?.length) {
    resultGenerator = generators;
  } else {
    resultGenerator = noGuardChildren;
  }

  if (item.leader_value >= 10000 && noGuardChildren.length >= 1) {
    return {
      haveReward: true,
      type: rewardTypes.FULL,
      item,
      useGenerators: false,
      generators: resultGenerator,
    };
  } else if (
    (item.leader_value < 10000 &&
      item.leader_value >= 4000 &&
      noGuardChildren.length >= 1) ||
    (item.leader_value <= 4000 && noGuardChildren.length >= 2)
  ) {
    return {
      haveReward: true,
      type: rewardTypes.PART,
      item,
      useGenerators: false,
      generators: resultGenerator,
    };
  } else {
    return {
      haveReward: false,
      type: rewardTypes.NONE,
      item,
      useGenerators: true,
      generators: resultGenerator,
    };
  }
};
