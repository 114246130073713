import Fetch from "@src/API/Fetch.js"
import Format from "@src/utils/Format.js"

export default async function Save(tree, meta, isNew) {

  let formatedTree = await Format('model', [tree, meta]);
  if ([
    "c4f0ee02-bc2f-4b76-ad02-7d0a4b95b01d",
    "b07a1edb-e74f-4d18-a144-1fa873fbfe1b",
    "959726c9-1bc5-4a63-b4a7-96d659a2caa5",
    "4447d4b6-8e12-4e24-8f68-8118851fa911",
    "61af360b-69f5-4fdd-a88f-f0a06b71cd70",
    "296644a4-4ad6-4c69-a05e-8b6f3211bdc5",
  ].includes(tree.id)) {
    isNew = true;
  }
  let url = isNew ? "node/save" : `node/${tree.id}/update`

  let result = await Fetch("POST", url, formatedTree)
  
  return await Format("tree", result);
}