<template>
  <g :class="[`tree`, `${fadeInActive ? 'fadeInActive' : ''}`]">
    <Branch
      ref="branch"
      :id="tree.id"
      :vertex="tree"
      @add="add"
      @select="select"
      @unselect="unselect"
      @remove="remove"
      @hide-tree="hideTree"
      @update="update"
    />
  </g>
</template>
<script>
import "./Tree.scss";
import Branch from "./Branch.vue";
import { v4 as uuid } from "uuid";
import GraphCalc from "./utils/GraphCalc.js";
import { useState } from "@src/App.store.js";
import duplicate from "@/mixins/duplicate";

export default {
  name: "Tree",
  components: {
    Branch,
    // Vertex
  },
  inject: [
    "incrementDuplicateCounter",
    "duplicateCounter",
    "duplicateLimit",
    "setShowDuplicateButton",
  ],
  mixins: [duplicate],
  data() {
    this.calcTree();
    return {
      rootID: this.Tree.id,
      fadeInActive: 0,
      tree: GraphCalc(this.Tree),
    };
  },
  setup() {
    return useState();
  },
  computed: {
    TreeID() {
      return this.Tree.id;
    },
  },
  watch: {
    TreeID: {
      handler() {
        if (this.Tree.id != this.rootID) {
          this.rootID = this.Tree.id;
          this.tree = GraphCalc(this.Tree);
        }
      },
    },
  },
  methods: {
    getById(id, branch) {
      if (!branch) branch = this.Tree;
      if (branch.id == id) return branch;

      let vertex = branch.children.find((val) => val.id == id);
      if (vertex) return vertex;

      for (let _branch of branch.children) {
        let result = this.getById(id, _branch);
        if (result) return result;
      }
      return null;
    },
    getActive(branch) {
      if (!branch) branch = this.Tree;
      if (branch.mode == "active") return branch;

      for (let _branch of branch.children) {
        let result = this.getActive(_branch);
        if (result) return result;
      }
      return null;
    },
    select(id) {
      let active = this.getActive();
      if (active) active.mode = "view";

      let current = this.getById(id);
      if (current) current.mode = "active";

      this.fadeInActive = true;

      this.tree = GraphCalc(this.Tree);
    },
    unselect() {
      let active = this.getActive();
      if (active) active.mode = "view";

      this.fadeInActive = false;

      this.tree = GraphCalc(this.Tree);
    },
    add(id) {
      let vertex = this.getById(id);

      if (vertex) {
        if (vertex.hideChildren) vertex.hideChildren = false;

        vertex.children.push({
          id: uuid(),

          flag_guardianship: false,

          personal_value: 100,
          group_value: 0,
          level_difference_reward: 0,
          personal_points: 100,
          group_points: 100,
          level: 0,
          leader_reward: 0,
          sponsor_reward: 0,

          children: [],
        });
        this.calcTree();
        this.tree = GraphCalc(this.Tree);
      }
    },
    remove(id, branch) {
      if (!branch) branch = this.Tree;

      let rIndex = branch.children.findIndex((val) => val.id == id);
      // console.log('this', rIndex);
      if (rIndex > -1) {
        branch.children.splice(rIndex, 1);

        this.calcTree();
        this.tree = GraphCalc(this.Tree);
        return true;
      }

      for (let _branch of branch.children) {
        let result = this.remove(id, _branch);
        if (result) return;
      }
      this.calcTree();
      this.tree = GraphCalc(this.Tree);
    },
    hideTree(id) {
      let active = this.getActive();
      if (active) active.mode = "view";

      let vertex = this.getById(id);
      if (vertex.children.length != 0)
        vertex.hideChildren = !vertex.hideChildren;

      this.tree = GraphCalc(this.Tree);
    },
    deactive(e) {
      let target = e.target.closest(".vertex-clip");
      let container = e.target.closest(".diagram-canvas");
      if (!target && container) {
        let active = this.getActive();
        if (active) active.mode = "view";

        this.fadeInActive = false;
      }
      this.tree = GraphCalc(this.Tree);
    },

    update({ id, key, value }) {
      let target = this.getById(id);
      if (target) target[key] = value;

      this.$forceUpdate();
      this.calcTree();

      this.tree = GraphCalc(this.Tree);
    },

    duplicate() {
      if (this.duplicateCounter.value <= this.duplicateLimit - 1) {
        const isDuplicated = this.startDuplicating(this.tree.id);

        if (isDuplicated) {
          this.incrementDuplicateCounter();
        }

        this.update(this.tree);
      }
    },
  },
  mounted() {
    window.localStorage.removeItem("duplicateItem");
    document.addEventListener("click", this.deactive);
  },
  unmounted() {
    document.removeEventListener("click", this.deactive);
  },
};
</script>
