/* eslint-disable */

const VERTEX_WIDTH = 220;
const VERTEX_HEIGHT = 300;

function calcBranchWidth(current, parent) {
  current.width = 0

  if(current.children.length == 0 || current.hideChildren) {
    current.width = VERTEX_WIDTH;
  } else {
    for(let branch of current.children) {
      calcBranchWidth(branch, current);      
      current.width += branch.width
    }
  }

  return;
}

function calcBranchPos(current, parent){
  if(!parent) {
    parent = {
      x: 0 - (current.width / 2),
      y: -300
    }
    current.x = 0;

  }

  if(!current.hideChildren && !parent.hide){
    current.y = parent.y + VERTEX_HEIGHT;
  } else if(parent.hideChildren || parent.hide){
    current.y = parent.y;
  } else {
    current.y = parent.y + VERTEX_HEIGHT;
  } 

  let usedWidth = 0;

  for(let branch of current.children){
    let branchW = branch.width;
    let currentX = current.x - ((current.width / 2) - (VERTEX_WIDTH / 2))
        
    if (current.hideChildren || parent.hideChildren || parent.hide || current.hide) {
      branch.x = usedWidth + ((branchW / 2) - (VERTEX_WIDTH / 2)) + currentX;
      branch.y = parent.y;
      branch.hide = true;
    } else {
      branch.x = usedWidth + ((branchW / 2) - (VERTEX_WIDTH / 2)) + currentX;
      branch.y = parent.y + VERTEX_HEIGHT;
      branch.hide = false;
  
      usedWidth += branchW;
    }

  
    if(branch.children.length > 0){
      calcBranchPos(branch, current)
    } else if(!current.hide){
      branch.y = current.y + VERTEX_HEIGHT;
    }
  }
  return;
}

function optimize(current, parent = {x: 0}){
  let right = current.children.filter(branch => branch.x > current.x)
  let left = current.children.filter(branch => branch.x < current.x).reverse()
  
  for(let branch of current.children){
    optimize(branch, current);
  }

  let lastX = current.children.length % 2 == 1 ? current.x : current.x - VERTEX_WIDTH / 2;
  let index = 0

  for(let branch of right){
    if(branch.width == VERTEX_WIDTH && branch.children.length <= 0){
      if(index > 0 && right[index - 1]){
        branch.x = lastX + VERTEX_WIDTH
        lastX = branch.x
      } else if(index < right.length && right[index + 1]){
        branch.x = lastX + VERTEX_WIDTH
        lastX = branch.x
      }
    } else lastX += VERTEX_WIDTH

    index++
  }

  lastX = current.children.length % 2 == 1 ? current.x : current.x + VERTEX_WIDTH / 2;
  index = 0

  for(let branch of left){
    if(branch.width == VERTEX_WIDTH && branch.children.length <= 0){
      if(index > 0 && right[index - 1]){
        branch.x = lastX - VERTEX_WIDTH
        lastX = branch.x
      } else if(index < right.length && right[index + 1]){
        branch.x = lastX - VERTEX_WIDTH
        lastX = branch.x
      }
    } else lastX -= VERTEX_WIDTH

    index++
  }

  // let lastX2 = 0

  // let skipped = 0;
  // for(let branch of current.children){
  //   optimize(branch, current)

    // if(branch.width == VERTEX_WIDTH && branch.children.length <= 0){
    //   if(index > 0 && current.children[index - 1]){
        
    //     if(!lastX) {
    //       branch.x = current.children[index - 1].x + VERTEX_WIDTH
    //     } else {
    //       branch.x = lastX + VERTEX_WIDTH
    //     }

    //     console.log("skip", branch.id, skipped);
        
    //     skipped += 1;

    //     lastX = branch.x

    //   } else if(index < current.children.length && current.children[index + 1]){         
        
    //     if(!lastX2) {
    //       branch.x = current.children[index + 1].x - VERTEX_WIDTH
    //     } else {
    //       branch.x = lastX2 - VERTEX_WIDTH
    //     }

    //     console.log("skip", branch.id, skipped);
        
    //     skipped += 1;

    //     lastX2 = branch.x
    //   }
    // } else if(skipped > 0 && branch.children.length >= 1){
  //     console.log("TRIGGGERED!", branch.id, skipped);
  //     translate(branch, skipped * VERTEX_WIDTH)      

  //     skipped = 0
  //   }
  //   index++
  // }
  return;
}

function translate(branch, factor){
  branch.x -= factor
  for(let subBranch of branch.children){
    translate(subBranch, factor)
  }
  return
}

export default function (tree){
  if(typeof tree !== "object") throw `The tree should not be an object, but a ${typeof tree}`

  const root = {
    ...tree,
  };

  calcBranchWidth(root, {
    x: 0,
    width: 0,
  })
  calcBranchPos(root);
  return root;
}
