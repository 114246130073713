<template>
  <button 
    :class="[{
      withIcon: icon,
      circle: circle
    }, type, 'btn']"
  >
    <Icon 
      class="icon"
      :class="iconPosition"
      v-if="icon && (iconPosition == 'left' || iconPosition == 'center') "
      :name="icon"
      :color="iconColor"
      :width="iconSize[0]"
      :height="iconSize[1]"
    />
    <slot/>
    <Icon
      class="icon"
      :class="iconPosition"
      v-if="icon && iconPosition == 'right'"
      :name="icon"
      :color="iconColor"
      :width="iconSize[0]"
      :height="iconSize[1]"
    />
  </button>
</template>
<script>
import Icon from "../Icon"
import "./Button.scss"

export default {
  name: "Button",
  components: {
    Icon
  },
  props :{
    type: {
      type: String,
      default: "default"
    },
    icon: {
      type: String,
      default: null
    },
    iconPosition: {
      default: "right"
    },
    iconColor: {
      default: '#000000'
    },
    iconSize: {
      default: ["24px", "24px"]
    },
    circle: {
      default: false
    },
    href: {
      default: null
    }
  }
}
</script>